import React, { useEffect, useImperativeHandle, useRef } from "react";
import { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ethers } from "ethers";
import { MiningPoolAbi, tokenAbi } from "../../../../utils/config";
import { eventBus } from "../../../../utils/tool";
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';


export default function StakeModal(props) {
    const [errorMsg, setErrorMsg] = useState(''); // 错误信息
    const [currentProvider, setCurrentProvider] = useState(null); // 当前的provider
    const [stakeInfo, setStakeInfo] = useState({}); // 质押信息 当前已质押数量(可解除质押的数量) 是否允许紧急撤离
    const [unstakeAmountInput, setUnstakeAmountInput] = useState(''); // 解除质押数量
    const [unstakeLoading, setUnstakeLoading] = useState(false) // 质押中loading
    const [forceWithdraw, setForceWithdraw] = useState(false) // 如果是true, 就显示紧急撤离界面
    const [forceWithdrawLoading, setForceWithdrawLoading] = useState(false) // 紧急撤离的loading
    const [isMax, setIsMax] = useState(false) // 是否点击了max
    const [reachTime, setReachTime] = useState(false) // 是否到达解锁时间
    const reachTimer = useRef(null)
    const currentUnlockTime = useRef(0)
    const { t } = useTranslation();
    useImperativeHandle(props.onRef, () => {
        // 需要将暴露的接口返回出去
        return {
            setStakeInfo: setStakeInfoLogic,
        };
    });

    const setStakeInfoLogic = (info) => {
        // 设置质押信息
        return new Promise((resolve, reject) => {
            console.log('解除质押信息', info)
            currentUnlockTime.current = info.unlockTime || 0
            // currentUnlockTime.current = 10
            setStakeInfo(info)
            resolve()
        })
    }

    useEffect(() => {
        reachTimer.current && clearInterval(reachTimer.current)
        if (currentUnlockTime.current > 0) {
            setReachTime(false)
            reachTimer.current = setInterval(() => {
                currentUnlockTime.current = currentUnlockTime.current - 1
                console.log('当前时间: ', currentUnlockTime.current, currentUnlockTime.current / 60)
                if (currentUnlockTime.current <= 0) {
                    setReachTime(true)
                    clearInterval(reachTimer.current)
                }
            }, 1000)
        } else {
            setReachTime(true)
        }

    }, [stakeInfo.unlockTime])

    const initProvider = () => {
        if (currentProvider) {
            return currentProvider
        }
        if (window.ethereum && !currentProvider) {
            const provider = new ethers.providers.Web3Provider(window.ethereum)
            setCurrentProvider(provider)
            return provider
        } else {
            console.log('No provider')
        }
    }


    const handleErr = (e) => {
        console.log(e)
        let t = ''
        if (e.reason) {
            t = e.reason
        } else {
            t = JSON.stringify(e)
        }
        setErrorMsg(t)
    }

    const handleForceWithdrawLogic = async () => {
        if (forceWithdrawLoading) {
            return
        }
        setErrorMsg('')
        setForceWithdrawLoading(true)
        const p = initProvider()
        const a = new ethers.Contract(stakeInfo.stakeContract, MiningPoolAbi, p.getSigner())
        try {
            const tx = await a.emergencyWithdraw()
            await tx.wait()
            // 解除成功
            eventBus.emit('forceWithdrawSuccess')
            props.handleClose && props.handleClose()
        } catch (e) {
            handleErr(e)
        }
        setForceWithdrawLoading(false)

    }

    const handleUnstakeLogic = async () => {
        if (unstakeLoading) {
            return
        }
        setErrorMsg('')
        // 解除质押, 判断解除质押数量是否大于0
        if (isNaN(parseFloat(unstakeAmountInput)) || parseFloat(unstakeAmountInput || 0) <= 0 || parseFloat(unstakeAmountInput) > (stakeInfo?.stakedAmount / Math.pow(10, stakeInfo?.stakeTokenInfo?.decimals))) {
            setErrorMsg(t('请输入正确的数量'))
            return
        }

        setUnstakeLoading(true)
        // const amount = ethers
        let amount = ethers.utils.parseUnits(unstakeAmountInput + '', stakeInfo.stakeTokenInfo.decimals)
        if (isMax && stakeInfo.stakedAmountBigNumber) {
            amount = stakeInfo.stakedAmountBigNumber
            console.log('max...', stakeInfo.stakedAmountBigNumber)
        }
        const p = initProvider()
        const a = new ethers.Contract(stakeInfo.stakeContract, MiningPoolAbi, p.getSigner())
        try {
            const tx = await a.withdraw(amount)
            const receipt = await tx.wait()
            // 解除成功
            eventBus.emit('withdrawSuccess')
            setUnstakeAmountInput('')
            props.handleClose && props.handleClose()
        } catch (e) {
            let tt = ''
            if (e.reason) {
                tt = e.reason
            } else {
                tt = JSON.stringify(e)
            }
            console.log(tt)
            if (tt.indexOf('not reach') > -1) {
                tt = t('未到达解锁时间')
            }
            setErrorMsg(tt)
        }

        setUnstakeLoading(false)
    }


    return <>
        <Modal centered show={props.show} onHide={() => {
            props.handleClose && props.handleClose()
        }} >
            {/* <Modal.Header closeButton>
                <Modal.Title>解除质押</Modal.Title>
            </Modal.Header> */}
            <Modal.Body>
                {!forceWithdraw && <div style={{ 'width': '100%' }} className="input-box my-4">
                    <div className="input-area d-flex flex-column flex-md-row mb-3 justify-content-center">
                        <div className="input-text unstake-input-box">
                            <input onChange={(v) => {
                                setUnstakeAmountInput(v.target.value)
                                setIsMax(false)
                            }} value={unstakeAmountInput} className="unstake-input" type="text" placeholder={0.00} />
                            <div className="unstake-input-a">Max</div>
                            <div onClick={() => {
                                // 设置为最大
                                setUnstakeAmountInput(stakeInfo?.stakedAmount / Math.pow(10, stakeInfo?.stakeTokenInfo?.decimals))
                                setIsMax(true)
                                console.log(unstakeAmountInput, 222)
                            }} className="unstake-input-a unstake-input-a-mask">

                            </div>
                        </div>
                        <div
                            onClick={() => {
                                handleUnstakeLogic()
                            }}
                            className="btn input-btn mt-2 mt-md-0 ml-md-3 unstake-input-btn">
                            {unstakeLoading && <span>
                                <i style={{ 'fontSize': '10px', 'marginRight': '5px' }} className="fa fa-spinner loading-css"></i>
                            </span>}
                            {unstakeLoading ? t('解除中') : t('解除质押')} </div>
                    </div>
                    <div>
                        <Alert variant="danger" show={errorMsg ? true : false} >
                            {errorMsg}
                        </Alert>
                    </div>
                    {!reachTime && <div className="unstake-bottom-row">
                        <Alert variant="primary" >
                            当前未到达解锁时间, 解除质押将会销毁25%的质押代币, 请谨慎操作
                        </Alert>

                    </div>}
                    <div className="unstake-bottom-row">

                        <div style={{ 'fontSize': '14px' }}>
                            {t('已质押')}: {stakeInfo?.stakedAmount / Math.pow(10, stakeInfo?.stakeTokenInfo?.decimals)} {stakeInfo?.stakeTokenInfo?.symbol}
                        </div>
                        <div>
                            {stakeInfo.userCanWithdraw && <div onClick={() => {
                                setForceWithdraw(true)
                            }} style={{ 'fontSize': '14px', 'color': '#0047FF', 'cursor': 'pointer' }}>
                                {t('紧急撤离')}
                            </div>}
                        </div>

                    </div>
                </div>}
                {forceWithdraw && <div style={{ 'width': '100%' }} className="input-box my-4">
                    <div>
                        {t('使用紧急撤离后,可立即从矿池中撤出您的所有质押代币, 不会获得任何奖励, 是否使用紧急撤离?')}
                    </div>
                    <div>
                        <Alert variant="danger" show={errorMsg ? true : false} >
                            {errorMsg}
                        </Alert>
                    </div>
                    <div className="force-withdraw-row" >
                        <div onClick={handleForceWithdrawLogic} style={{ 'marginRight': '10px' }} className="btn btn-bordered-white btn-smaller">
                            {forceWithdrawLoading && <span>
                                <i style={{ 'fontSize': '10px', 'marginRight': '5px' }} className="fa fa-spinner loading-css"></i>
                            </span>}
                            {t('确认')}
                        </div>
                        <div onClick={() => {
                            setErrorMsg('')
                            setForceWithdraw(false)
                        }} className="btn btn-bordered-white btn-smaller">
                            {t('取消')}
                        </div>
                    </div>
                </div>}
            </Modal.Body>
        </Modal>
    </>
}